/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Self host fonts using Typefaces.js https://github.com/KyleAMathews/typefaces

// Load Michroma typeface
require('typeface-michroma');
